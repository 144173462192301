<template>
    <div>
      <div class="wrapper innerBg">
      <!--header-->
      <div class="header">
        <div class="pagesize">
          <div class="headerNav">
            <router-link class="headerlogo" to="/index"><img src="./images/logo.png" alt="">臺北市士林區公所能源管理系統</router-link>
            <div class="state web">
              <div class="circle"></div>通訊正常
            </div>
            <ul class="headerNav_menu web">
            <li><router-link to="/index">首頁</router-link></li>
            <li><router-link to="./public">公共用電</router-link></li>
            <li><router-link to="./floor" >樓層用電</router-link></li>
            <li><router-link to="./ice" >冰水主機</router-link></li>
            <li><router-link to="./solar" >光電系統</router-link></li>
            <li><router-link to="./download" class="on" >下載專區</router-link></li>
            </ul>
            <div class="menuic mobile btn_menu">
              <img class="btn_pic01 btn_menuck" src="./images/icon_menu.png" alt="">
              <img class="btn_pic02 btn_menuck" src="./images/icon_close.png" alt="">
            </div>
          </div>
        </div>
        <!--小網menu-->
        <div class="open_nav">
          <ul>
            <li class="m_nav"><router-link to="/index">首頁</router-link></li>
            <li class="m_nav"><router-link to="./public">公共用電</router-link></li>
            <li class="m_nav"><router-link to="./floor" >樓層用電</router-link></li>
            <li class="m_nav"><router-link to="./ice" >冰水主機</router-link></li>
            <li class="m_nav"><router-link to="./solar" >光電系統</router-link></li>
            <li class="m_nav"><router-link to="./download" >下載專區</router-link></li>
          </ul>
          <div class="state">
            <div class="circle"></div>通訊正常
          </div>
        </div>
      </div>
      <!--內容開始-->
      <div class="contain">
        <div class="pagesize">
          <div class="main">
            <div class="lsDate">最後更新時間 {{upDateTime}}</div>
            <div class="graph graphM mgb mgt">
              <div class="infoOpt">
                <p>圖表資訊選項</p>
                <div class="graphBar">
                  <div class="interval">
                    <input type="checkbox" id="c1" name="yesOrNo" v-model="selectCheck">
                    <label for="c1">選擇區間<span></span></label>
                  </div>
                  <div class="dateSpan">
                    <div class="btn_date">
                      <a @click="dateTypeSelect('date')" :class="{ dateActive: DateType === 'date'}">日</a>
                      <a @click="dateTypeSelect('month')" :class="{ dateActive: DateType === 'month'}">月</a>
                      <a @click="dateTypeSelect('year')" :class="{ dateActive: DateType === 'year'}">年</a>
                    </div>
                    <div class="inputCa wM">
                      <date-picker style="width:auto" placeholder="起始日期" v-model="beginDate" :type="DateType" valueType="format"></date-picker>
                      <!-- <input type="text" value="" placeholder="起始日期">
                      <button class="calendar"></button> -->
                    </div>
                  </div>
                  <div class="interCa" v-if='selectCheck'>
                  <p class="dib">至</p></div>
                  <div class="dateSpan">
                    <!-- <div class="btn_date">
                      <a href="#">日</a>
                      <a href="#">月</a>
                      <a href="#">年</a>
                    </div> -->
                    <div class="inputCa wM" v-if='selectCheck'>
                      <date-picker style="width:auto" placeholder="結束日期" v-model="endDate" :type="DateType" valueType="format"></date-picker>
                      <!-- <input type="text" value="" placeholder="結束日期">
                      <button class="calendar"></button> -->
                    </div>
                  </div>
                </div>
                <div class="optBox">
                  <div class="opt">
                    <p>選項一</p>
                    <select class="wM" v-model="optionOne">
                      <option v-for="i in optionArray" :value="i" :key="i" >{{i}}</option>
                      <!-- <option data-img-src="images/rect6.png">請選擇</option>
                      <option data-img-src="images/rect1.png">首頁</option>
                      <option data-img-src="images/rect2.png">公共用電</option>
                      <option data-img-src="images/rect3.png">樓層用電</option>
                      <option data-img-src="images/rect4.png">空調設備</option>
                      <option data-img-src="images/rect5.png">光電系統</option> -->
                    </select>
                    <select class="wM mgl" v-model="valueOne">
                      <option v-for="i in optionSet(optionOne)" :value="i" :key="i">{{i}}</option>
                    </select>
                  </div>
                  <div class="boxBorder web"></div>
                  <div class="graphBorder mobile"></div>
                  <div class="opt">
                    <p>選項二</p>
                    <select class="wM" v-model="optionTwo">
                      <option v-for="i in optionArray" :value="i" :key="i">{{i}}</option>
                      <!-- <option data-img-src="images/rect6.png">請選擇</option>
                      <option data-img-src="images/rect1.png">首頁</option>
                      <option data-img-src="images/rect2.png">公共用電</option>
                      <option data-img-src="images/rect3.png">樓層用電</option>
                      <option data-img-src="images/rect4.png">空調設備</option>
                      <option data-img-src="images/rect5.png">光電系統</option> -->
                    </select>
                    <select class="wM mgl" v-model="valueTwo">
                      <option v-for="i in optionSet(optionTwo)" :value="i" :key="i">{{i}}</option>
                      <!-- <option>請選擇</option> -->
                    </select>
                  </div>
                  <div class="boxBorder web"></div>
                  <div class="graphBorder mobile"></div>
                  <div class="opt opt3">
                    <p>選項三</p>
                    <select class="wM" v-model="optionThree">
                      <option v-for="i in optionArray" :value="i" :key="i" >{{i}}</option>
                      <!-- <option data-img-src="images/rect6.png">請選擇</option>
                      <option data-img-src="images/rect1.png">首頁</option>
                      <option data-img-src="images/rect2.png">公共用電</option>
                      <option data-img-src="images/rect3.png">樓層用電</option>
                      <option data-img-src="images/rect4.png">空調設備</option>
                      <option data-img-src="images/rect5.png">光電系統</option> -->
                    </select>
                    <select class="wM mgl" v-model="valueThree">
                      <option v-for="i in optionSet(optionThree)" :value="i" :key="i">{{i}}</option>
                      <!-- <option>請選擇</option> -->
                    </select>
                  </div>
                </div>
              </div>
              <div class="btn_graph">
                <a @click="historyQuery(DateType)">繪製圖表</a>
              </div>
            </div>
            <div class="graph graphM">
              <div class="infoOpt">
              <p>圖表資訊分析</p>
              <div class="btn_confirm">
                <!-- <vue-csv-downloader
                  :data="dowloadData"
                  :fields="fields"
                >資料下載 -->
                <!-- </vue-csv-downloader> -->
                <a @click="clickDownloadCsv">資料下載</a>
            </div>
              </div>
              <Highcharts :options="TotalsChart" ref="highchart" />
              <!-- <img class="web" src="./images/graph7.png">
              <img class="mobile" src="./images/graph7_m.png"> -->
            </div>
          </div>
        </div>
      </div>
      <!--footer-->
      <footer>
        <div class="pagesize">
          <div class="ftLogo"><img src="./images/logo2.png" alt="">
          </div>
          <div class="copyright">
            Copyright © All Rights Reserved.
          </div>
        </div>
      </footer>
    </div>
    </div>
</template>

<style scoped>
  @import './css/global.css';
  a {
    cursor: pointer;
  }
  .dateActive {
    background-color: #128771;
    color: white
  }
  .inputW {
    width: 170px;
  }
</style>

<script>
import importJQ from './js/common'
import $ from 'jquery'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { mapActions, mapState, mapMutations } from 'vuex'
import { dbDataHandle } from '../store/function/index'
import { DateTime } from 'luxon'
import downloadCsv from 'download-csv'
export default {
  components: { DatePicker },
  data () {
    return {
      dowloadData: [
        { name: 'public', '2021-03-31': 5555 },
        { name: 'floor' },
        { name: 'solar' }
      ],
      fields: ['name', '2021-03-31'],
      selectCheck: false,
      DateType: 'date',
      beginDate: DateTime.local().toFormat('yyyy-MM-dd'),
      endDate: DateTime.local().toFormat('yyyy-MM-dd'),
      upDateTime: DateTime.local().toFormat('yyyy-MM-dd HH:mm'),
      optionOne: '公共用電',
      optionTwo: '樓層用電',
      optionThree: '光電系統',
      valueOne: '累積用電量',
      valueTwo: '累積用電量',
      valueThree: '累積發電量',
      optionArray: ['公共用電', '樓層用電', '空調設備', '光電系統'],
      publicOption: ['累積用電量'],
      floorOption: ['累積用電量'],
      iceOption: ['累積用電量'],
      solarOption: ['累積發電量', '容量因數'],
      TotalsChart: {
        chart: {
          backgroundColor: null
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: ['2021-03-1 00:00', '2021-03-1 02:00', '2021-03-1 04:00']
        },
        yAxis: [
          {
            title: {
              text: ''
            }
          },
          {
            title: {
              text: ''
            },
            opposite: true
          },
          {
            title: {
              text: ''
            },
            opposite: true
          }
        ],
        tooltip: {
          shared: true
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          // floating: true,
          verticalAlign: 'top',
          x: 0,
          y: 30
        },
        series: [
          {
            yAxis: 0,
            name: '公共用電-累積電量',
            data: [2, 2, 2]
          },
          {
            yAxis: 1,
            name: '公共用電-功率因數',
            data: [9, 21, 4]
          },
          {
            yAxis: 2,
            name: '太陽光電-累計電量',
            data: [1, 44, 45]
          }
        ],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                y: 0
              }
            }
          }]
        }
      }
    }
  },
  methods: {
    ...mapMutations([
      'GETUPDATE_TIME'
    ]),
    ...mapActions([
      'getdayData',
      'getHourData',
      'getDayInterval',
      'getMonData',
      'getLastMerged',
      'getMonInterval',
      'getYearInterval'
    ]),
    clickDownloadCsv () {
      downloadCsv(this.dowloadData, this.fields, '下載專區.csv')
    },
    handleNum (val, num) {
      return dbDataHandle(val, num)
    },
    optionSet (option) {
      let arr = []
      if (option === '公共用電') {
        arr = this.publicOption
        return arr
      }
      if (option === '樓層用電') {
        arr = this.floorOption
        return arr
      }
      if (option === '空調設備') {
        arr = this.iceOption
        return arr
      }
      if (option === '光電系統') {
        arr = this.solarOption
        return arr
      }
    },
    dateTypeSelect (str) {
      this.DateType = str
      if (str === 'date') {
        this.beginDate = DateTime.local().toFormat('yyyy-MM-dd')
        this.endDate = DateTime.local().toFormat('yyyy-MM-dd')
      }
      if (str === 'month') {
        this.beginDate = DateTime.local().toFormat('yyyy-MM')
        this.endDate = DateTime.local().toFormat('yyyy-MM')
      }
      if (str === 'year') {
        this.beginDate = DateTime.local().toFormat('yyyy')
        this.endDate = DateTime.local().toFormat('yyyy')
      }
    },
    async historyQuery (type) {
      if (!this.selectCheck) {
        if (type === 'date') {
          await this.getHourData(this.beginDate)
          this.getTotalChart(this.EhourData)
        } else if (type === 'month') {
          await this.getdayData(this.beginDate)
          this.getTotalChart(this.EdayData)
        } else if (type === 'year') {
          await this.getMonData(this.beginDate)
          this.getTotalChart(this.EmonData)
        }
      } else {
        const date = {
          beginDate: this.beginDate,
          endDate: this.endDate
        }
        if (type === 'date') {
          await this.getDayInterval(date)
          this.getTotalChart(this.InterData)
        } else if (type === 'month') {
          await this.getMonInterval(date)
          this.getTotalChart(this.MonInterData)
        } else if (type === 'year') {
          await this.getYearInterval(date)
          this.getTotalChart(this.YearInterData)
        }
      }
    },
    getTotalChart (arr) {
      const optionArray = [this.optionOne, this.optionTwo, this.optionThree]
      const valueArray = [this.valueOne, this.valueTwo, this.valueThree]
      const capacity = 30.72
      let capacityHour = 1
      if (arr.data[0].date) {
        const type = arr.data[0].date.split('-').length
        if (type === 3) capacityHour = capacityHour * 24
        if (type === 2) capacityHour = capacityHour * 24 * 30
        if (type === 1) capacityHour = capacityHour * 24 * 360
      }
      for (let i = 0; i < this.TotalsChart.series.length; i++) {
        this.TotalsChart.series[i].data = []
      }
      this.TotalsChart.xAxis.categories = []
      for (let i = 0; i < arr.data.length; i++) {
        if (arr.data[i].hhmm) {
          this.TotalsChart.xAxis.categories.push(arr.data[i].hhmm)
        } else if (arr.data[i].date) {
          this.TotalsChart.xAxis.categories.push(arr.data[i].date)
        }
        for (let k = 0; k < optionArray.length; k++) {
          if (optionArray[k] === '公共用電') {
            this.TotalsChart.series[k].name = optionArray[k] + '-' + valueArray[k]
            this.TotalsChart.series[k].data.push(this.handleNum(arr.data[i].detail.public.meter))
          } else if (optionArray[k] === '光電系統') {
            if (valueArray[k] === '累積發電量') {
              this.TotalsChart.series[k].name = optionArray[k] + '-' + valueArray[k]
              this.TotalsChart.series[k].data.push(this.handleNum(arr.data[i].detail.public.pv))
            } else if (valueArray[k] === '容量因數') {
              this.TotalsChart.series[k].name = optionArray[k] + '-' + valueArray[k]
              this.TotalsChart.series[k].data.push(this.handleNum(arr.data[i].detail.public.pv / capacity * capacityHour * 100))
            }
          } else if (optionArray[k] === '樓層用電') {
            let floorKwh = 0
            this.TotalsChart.series[k].name = optionArray[k] + '-' + valueArray[k]
            for (let p = 0; p < arr.data[i].detail.floor.length; p++) {
              floorKwh = arr.data[i].detail.floor[p].kWh + floorKwh
            }
            this.TotalsChart.series[k].data.push(this.handleNum(floorKwh))
          } else if (optionArray[k] === '空調設備') {
            this.TotalsChart.series[k].name = optionArray[k] + '-' + valueArray[k]
            this.TotalsChart.series[k].data.push(this.handleNum(arr.data[i].detail.public.iceKwh))
          }
        }
      }
      this.download()
    },
    download () {
      const downloadArr = []
      this.fields = ['name']
      this.fields = this.fields.concat(this.TotalsChart.xAxis.categories)
      for (let i = 0; i < this.TotalsChart.series.length; i++) {
        const obj = {
          name: this.TotalsChart.series[i].name
        }
        downloadArr.push(obj)
      }
      for (let i = 0; i < this.TotalsChart.xAxis.categories.length; i++) {
        for (let j = 0; j < downloadArr.length; j++) {
          downloadArr[j][`${this.TotalsChart.xAxis.categories[i]}`] = this.TotalsChart.series[j].data[i]
        }
      }
      this.dowloadData = downloadArr
    }
  },
  async mounted () {
    this.historyQuery(this.DateType)
    await importJQ()
    $(function () {
      $(document).ready(function () {
        var $win = $(window)
        $win.bind('resize', function () {
          try {
            $('.contain').css('min-height', $win.height() - $('footer').outerHeight() - $('.contain').offset().top)
          } catch (err) {}
        }).resize()
      })
    })
  },
  computed: {
    ...mapState([
      'EhourData',
      'EdayData',
      'EmonData',
      'lastCurrentData',
      'InterData',
      'MonInterData',
      'YearInterData'
    ])
  }
}
</script>
